var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"SchoolCardReaderRateCard pa-3 mb-4"},[_c('v-toolbar',{staticClass:"elevation-0 pa-0",attrs:{"color":"transparent"}},[_c('v-card-title',{staticClass:"px-0 accent--text font-weight-bold"},[_vm._v(" 卡機費率設定 ")]),_c('v-spacer'),(_vm.showCloseBtn)?_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cardreaders,"items-per-page":_vm.cardreaders.length,"hide-default-footer":"","disable-sort":"","show-select":"","item-key":"emsclasscardreaderid"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-sheet',{staticClass:"pa-4",attrs:{"color":"white","elevation":"1","width":"300"}},[_c('div',{staticClass:"subtitle-1 accent--text font-weight-bold"},[_vm._v(" 依勾選項目設定費率 ")]),_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{directives:[{name:"permission-without-city",rawName:"v-permission-without-city",value:(['keelung', 'changhua']),expression:"['keelung', 'changhua']"}],attrs:{"filled":"","solo":"","placeholder":"請輸入費率","width":"300","rules":[
                    function (v) { return !!v || '必填'; },
                    function (v) {
                      return Number(v).toString() === String(v) || '格式不符'
                    },
                    function (v) {
                      return (
                        (Number(v) >= 0.01 && Number(v) <= 99.99) ||
                        '需介於 0.01 ~ 99.99'
                      )
                    },
                    function (v) {
                      return (
                        _vm.numeral(v).format('0.[00]') === String(v) ||
                        "小數點最多2位數"
                      )
                    }
                  ]},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=$$v},expression:"rate"}}),_c('v-text-field',{directives:[{name:"permission-with-city",rawName:"v-permission-with-city",value:(['keelung', 'changhua']),expression:"['keelung', 'changhua']"}],attrs:{"filled":"","solo":"","placeholder":"請輸入費率","width":"300","rules":[
                    function (v) { return !!v || '必填'; },
                    function (v) {
                      return Number(v).toString() === String(v) || '格式不符'
                    },
                    function (v) {
                      return (
                        (Number(v) >= 0.1 && Number(v) <= 63.9) ||
                        '需介於 0.1 ~ 63.9'
                      )
                    },
                    function (v) {
                      return (
                        _vm.numeral(v).format('0.[0]') === String(v) ||
                        "小數點最多1位數"
                      )
                    }
                  ]},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=$$v},expression:"rate"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"disabled":!_vm.valid || !_vm.rate || !_vm.selected || _vm.selected.length === 0,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 批次修改 ")]),(!_vm.selected || _vm.selected.length === 0)?_c('div',{staticClass:"error--text caption"},[_vm._v(" 請選擇卡機 ")]):_c('div',{staticClass:"mb-5"})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }