<template>
  <v-card class="edit-classroom-name-card pa-3 mb-4">
    <v-toolbar color="transparent" class="elevation-0 pa-0">
      <v-card-title class="px-0 accent--text font-weight-bold"
        >各班級與教室對照表</v-card-title
      >
      <v-spacer></v-spacer>
      <v-btn
        @click="saveTemporary()"
        :disabled="!valid"
        color="secondary"
        class="mr-4 px-8 accent--text"
      >
        暫存
      </v-btn>
      <v-btn @click="save()" :disabled="!valid" color="primary">
        確認修改
      </v-btn>
      <v-btn @click="close()" plain v-if="showCloseBtn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-data-table
          :headers="headers"
          :items="classes"
          :items-per-page="classes.length"
          hide-default-footer
          disable-sort
        >
          <template v-slot:[`item.name`]="{ item }">
            <v-text-field
              v-model="item.name"
              class="ma-0 pa-0"
              hide-details
              outlined
              :rules="[
                (v) => !!v || '必填',
                (v) => {
                  if (!v) return false
                  const pattern = /\S$/
                  return pattern.test(v) || '格式不符'
                }
              ]"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import BranchSchool from '@/api/ems/BranchSchool'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    schoolId: {
      type: String,
      required: true
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      valid: true,
      classes: [],
      headers: [
        {
          text: '教室編號',
          value: 'serialNumber'
        },
        {
          text: '班級名稱',
          value: 'name',
          cellClass: ['edit', 'pa-0']
        },
        {
          text: 'Modbus ID',
          value: 'meterModbusId'
        },
        {
          text: '所屬大樓',
          value: 'building'
        },
        {
          text: '樓層',
          value: 'floor'
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created: function () {},
  mounted: function () {
    this.getClassName()
  },
  destroyed() {},
  methods: {
    ...mapActions({
      getTags: 'page/getTags',
      showMsg: 'snackbar/show'
    }),
    close() {
      this.$emit('close', true)
    },
    getClassName() {
      BranchSchool.getClassName(this.schoolId)
        .then((resp) => {
          this.classes = resp.data.classes
        })
        .catch((error) => {
          console.log(error)
        })
    },
    save() {
      BranchSchool.updateClassName(this.schoolId, this.classes, false)
        .then((resp) => {
          this.getTags()
          this.showMsg({
            message: '更新成功',
            closable: true,
            time: 3000
          })
          this.close()
        })
        .catch(() => {
          this.showMsg({
            message: '更新失敗',
            color: 'error',
            closable: true,
            time: 3000
          })
        })
    },
    saveTemporary() {
      BranchSchool.updateClassName(this.schoolId, this.classes, true)
        .then((resp) => {
          this.showMsg({
            message: '暫存完成',
            closable: true,
            time: 3000
          })
        })
        .catch(() => {
          this.showMsg({
            message: '更新失敗',
            color: 'error',
            closable: true,
            time: 3000
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/schoolInfoDialog.scss';
</style>
