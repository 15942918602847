<template>
  <v-dialog fullscreen hide-overlay v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="orange darken-2"
        dark
        v-bind="attrs"
        v-on="on"
        class="my-3 mr-4"
      >
        卡機費率設定
      </v-btn>
    </template>
    <CardReaderRateCard
      :schoolId="schoolId"
      showCloseBtn
      @close="dialog = false"
    />
  </v-dialog>
</template>

<script>
import CardReaderRateCard from './CardReaderRateCard'

export default {
  name: 'CardReaderRateDialog',
  mixins: [],
  components: {
    CardReaderRateCard
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    schoolId: {
      type: String,
      require: true
    }
  },
  data: function () {
    return {
      dialog: false,
      meterData: null
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.dialog = val
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    save() {
      this.$emit('save', this.meterData)
      this.dialog = false
    },
    close() {
      this.$emit('close')
      this.dialog = false
    }
  }
}
</script>

<style></style>
