var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"edit-classroom-name-card pa-3 mb-4"},[_c('v-toolbar',{staticClass:"elevation-0 pa-0",attrs:{"color":"transparent"}},[_c('v-card-title',{staticClass:"px-0 accent--text font-weight-bold"},[_vm._v("各班級與教室對照表")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4 px-8 accent--text",attrs:{"disabled":!_vm.valid,"color":"secondary"},on:{"click":function($event){return _vm.saveTemporary()}}},[_vm._v(" 暫存 ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" 確認修改 ")]),(_vm.showCloseBtn)?_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.classes,"items-per-page":_vm.classes.length,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"hide-details":"","outlined":"","rules":[
              function (v) { return !!v || '必填'; },
              function (v) {
                if (!v) { return false }
                var pattern = /\S$/
                return pattern.test(v) || '格式不符'
              }
            ]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }