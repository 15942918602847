<template>
  <div class="mt-6">
    <template v-for="group in meterGroup">
      <v-card
        class="pa-3 ems-opacity-50-bg frosted-glass mb-4"
        :key="group.type"
        v-if="
          groupByTypeMeters[group.type] &&
          groupByTypeMeters[group.type].length > 0
        "
      >
        <v-card-title class="accent--text font-weight-bold">{{
          group.name
        }}</v-card-title>
        <v-card-text
          v-for="(meter, index) in groupByTypeMeters[group.type]"
          :key="meter.id"
        >
          <v-row>
            <v-col cols="12" lg="6">
              <div class="text-subtitle-1 grey--text">電表名稱</div>
              <v-text-field
                v-model="meter.name"
                outlined
                dense
                :readonly="!editAuthority"
                :disabled="!isSchoolAdmin"
                :rules="[
                  (v) => !!v || '必填',
                  (v) => {
                    const pattern = /^[_0-9a-zA-Z.,\s\u4e00-\u9fa5]+$/
                    return pattern.test(v) || '格式不符'
                  }
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pa-0"></v-col>
            <v-col v-if="group.showAttrs.serialNumber" cols="12" lg="6">
              <div class="text-subtitle-1 grey--text">裝置編號</div>
              <v-text-field
                v-model="meter.serialNumber"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>
            <v-col v-if="group.showAttrs.chargePlanId" cols="12" lg="6">
              <div class="text-subtitle-1 grey--text">電費計價</div>
              <v-select
                :readonly="!editAuthority"
                outlined
                v-model="meter.chargePlanId"
                dense
                :items="branchPlan"
                item-text="name"
                item-value="id"
                :disabled="!isSchoolAdmin"
              ></v-select>
            </v-col>
            <v-col v-if="group.showAttrs.meterCapacity" cols="12" lg="6">
              <div
                v-if="meter.type === 'SOLARMETER'"
                class="text-subtitle-1 grey--text"
              >
                裝置容量 kWp
              </div>
              <div v-else class="text-subtitle-1 grey--text">契約容量 kW</div>
              <v-text-field
                v-if="meter.type === 'SOLARMETER'"
                :value="meter.meterCapacity"
                @input="updateSolarMeterCapcity($event, meter)"
                outlined
                dense
                :readonly="!editAuthority"
                :rules="[(v) => v === 0 || !!v || '必填', limit2DecimalPlaces]"
                type="number"
                :disabled="!isSchoolAdmin"
              ></v-text-field>
              <v-text-field
                v-else
                v-model="meter.meterCapacity"
                outlined
                dense
                :readonly="!editAuthority"
                :rules="[(v) => v === 0 || !!v || '必填', limit2DecimalPlaces]"
                type="number"
                :disabled="!isSchoolAdmin"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    value: {
      type: Array,
      required: true
    },
    schoolId: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      loadingMeter: false,
      meters: this.value,
      meterGroup: [
        {
          type: 'SCHOOLMETER',
          name: '學校既設總表',
          showAttrs: {
            serialNumber: true,
            chargePlanId: true,
            meterCapacity: true
          }
        },
        {
          type: 'ACMETER',
          name: '新設冷氣總表',
          showAttrs: {
            serialNumber: true,
            chargePlanId: true,
            meterCapacity: true
          }
        },
        {
          type: 'SOLARMETER',
          name: '再生能源發電量測電表',
          showAttrs: {
            serialNumber: true,
            price: true,
            meterCapacity: true
          }
        }
      ],
      limit2DecimalPlaces: (value) => {
        let rule = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/)
        return rule.test(value) || '僅允許輸入最多小數點兩位的數字'
      }
    }
  },
  computed: {
    ...mapGetters({
      branchPlan: 'config/branchPlan',
      userRole: 'user/userRole'
    }),
    isSchoolAdmin() {
      return this.userRole.role === 'SCH_ADMIN'
    },
    editAuthority() {
      // return this.authorities.indexOf('UPDATE_SCHOOL') > -1
      return ['SCH_ADMIN'].includes(this.userRole.role)
    },
    groupByTypeMeters() {
      const data = {}
      this.meters.forEach((meter) => {
        if (!data[meter.type]) {
          data[meter.type] = []
        }
        data[meter.type].push(meter)
      })
      return data
    }
  },
  watch: {
    value(val) {
      this.meters = val
    },
    meters: {
      handler(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      immediate: true,
      deep: true
    }
  },
  created: function () {},
  mounted: function () {},
  destroyed() {},
  methods: {
    updateSolarMeterCapcity(value, meter) {
      this.meters.find(({ id }) => id === meter.id).meterCapacity = value
      this.$emit('updateSolarMeterCapcity', this.meters)
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  .v-card__text:not(:last-child) {
    border-bottom: solid 1px #abc0de;
  }
}
</style>
